import Vue from 'vue'
import Router from "vue-router";
import VueMeta from 'vue-meta';
import store from './store';

Vue.use(Router)
Vue.use(VueMeta)



const router = new Router({
  mode: "history",
  scrollBehavior (to, from, savedPosition) {
    to
    from 
    savedPosition
    return new Promise((resolve, reject) => {
      reject
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 300)
    })
  },
  
    routes: [
      // {
            //   // path: '/',
            //   // name:'alkafeel',
            
            //   // component: () => import('./views/user/index.vue'),

            //   children: [
            //     {
            //       name: 'Home',
            //       path: '',
            //       title:'Home',
            //       component: () => import(/*webpackChunkName:"home" */'./views/user/home.vue')
            //     },
            // ]
      // },

      {
        path: '/auth',
        name:'auth',
        component: () => import('./views/pages/Index.vue'),
        children: [{
          name: 'Login',
          path: '',
          component: () => import('./views/pages/Login.vue')
        }]
      },
      
      {
        path: '/',
        name:"",
        component: () => import('./views/dashboard/Index.vue'),
        beforeEnter: (to, from, next) => {
           if (localStorage.getItem('tokinn') != null & store.state.AdminInfo.authe==true) {
          
            next();
          } else {
            next({
              name: 'Login'
            });
          }
        },   
        children: [
          {
            name: 'articles',
            path: 'articles',
            component: () => import('./views/dashboard/articles.vue')
          },
          {
            name: 'seera',
            path: 'seera',
            component: () => import('./views/dashboard/seera.vue')
          },
          {
            path: 'videos',
            name:'videos',
            component: () => import('./views/dashboard/videos.vue'),

          },
          {
            path: 'Gallery',
            name:'Gallery',
            component: () => import('./views/dashboard/Gallery.vue'),
          },

          {
            path: 'details',
            name:'details',
            component: () => import('./views/dashboard/details.vue'),
            
          },
          {
            path: 'info',
            name:'info',
            component: () => import('./views/dashboard/info.vue'),
            
          },
          {
            path:'zyara',
            name:'zyara',
            component: () => import('./views/dashboard/zyara.vue'),
          },
          {
            path:'contactus',
            name:'contactus',
            component: () => import('./views/dashboard/contactus.vue'),
          }


       
        ]   
        
      },

    
   
       
    ]
    });
    
    
    
    
    router.beforeEach((to, from, next) => {
     
        window.document.title = to.name ;
   
      next();
    });
    
    export default router;

